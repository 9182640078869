<template>
    <div class="row mb">
        <div class="col-12 col-lg-10">
            <div class="crefo-input">
                <div class="input-group">
                    <label>Bitte geben Sie Ihren Unternehmensnamen ein und wählen Sie das korrekte Unternehmen aus der Liste.</label>
                    <crefo-autosuggest-company
                        placeholder-input="z.B. „Mustermann GmbH”"
                        :suggestions="fetchCompanies"
                        :max-suggestions="10"
                        @crefo-autosuggest-company-selected="onCompanySelected"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import CrefoAutosuggestCompany from '@dm/crefo-autosuggest-company'
    export default {
        name: 'UboLandingpageSucheUnternehmen',
        components: { CrefoAutosuggestCompany },
        data () {
            return {
                ajaxCancel: null
            }
        },
        methods: {
            fetchCompanies (searchQuery) {
                if (this.ajaxCancel !== null) {
                    this.ajaxCancel.cancel()
                }
                this.ajaxCancel = axios.CancelToken.source()
                let queryString = ''
                if (typeof searchQuery === 'string' && searchQuery.length) {
                    queryString = searchQuery.trim().replace(/%20/g, '*%20')
                        .concat('*')
                }
                return axios.get(`/api/gatinator/smart-signup/${queryString}`, { cancelToken: this.ajaxCancel.token })
            },
            onCompanySelected (company, index) {
                if (company && typeof company.crefonummer === 'string' && company.crefonummer.length) {
                    this.$emit('company-selected', company.crefonummer)
                } else {
                    this.$emit('company-selected', null)
                }
            }
        }
    }
</script>
