import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { StatusInformationJson } from '@/types/api/wkv-ruv-backend/StatusInformationJson'

axios.defaults.headers.common = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
}

/**
 * Sends a GET request to receive all monorepo features.
 */
export const getFeaturesMonorepo = () =>
    axios.get<FeatureSettings>('/cbra/features')

/**
 * Get Member data for current user.
 */
export const getMitglied = () =>
    axios.get<MemberWrapper>(`/cbra/members`)

/**
 * Get User data for current user.
 */
export const getUser = () => {
    let loginHost = 'login.creditreform.de'
    const matchingHostPos = window.location.host.indexOf('cp.meine.creditreform.de')
    if (matchingHostPos !== -1) {
        loginHost = window.location.host.substring(0, matchingHostPos) + 'sso.' + loginHost
    }

    return axios.get<Person>(`https://${loginHost}/api/user-management/persons/me`, {
        transformRequest: (data, headers) => {
            transformHeadersForCORS(headers)
            return data
        }
    })
}

/**
 * Send a GET request to fetch matching company.
 */
export const getKurzprofil = (crefonummer: string): Promise<AxiosResponse<Kurzprofil>> =>
    axios.get<Kurzprofil>(`/api/gatinator/kurzprofil/${crefonummer}`)

/**
 * Send a POST request to get R+V Url.
 */
export const postRuvInitialisierung = (zustimmungsinformationen: Zustimmungsinformationen): Promise<AxiosResponse<RedirectUrl>> =>
    axios.post<RedirectUrl>(`/api/wkv-ruv-backend/ruv-initialisierung`, zustimmungsinformationen)

/**
 * Send a POST request to get R+V Url.
 */
export const postRuvStatus = (statusinformation: StatusInformationJson, accesstoken: string) =>
    axios.post<string>(
        `/api/wkv-ruv-backend/ruv-status`,
        statusinformation,
        {
            headers: { 'MC-AccessToken': accesstoken }
        }
    )

/**
 * Send a GET request to ping service.
 */
export const getPing = () =>
    axios.get<string>('/api/wkv-ruv-backend/ping')

/**
 * Get business data for current member.
 */
export const getBusinessDataForCurrentMember = () =>
    axios.get<BusinessDataWrapper>('/cbra/businessdata/')

/**
 * Search companies.
 */
export const postAdvancedSearchByCrefonummer = (crefonummer: string) =>
    axios.post<SearchResponseWrapper>('/cbra/advancedsearch/', {
        businessId: crefonummer,
        country: 'DE',
        registerData: {
            registerArt: null,
            registerNummer: null
        }
    })

/**
 * Transform axios headers for cors requests.
 */
function transformHeadersForCORS (headers: AxiosRequestHeaders): AxiosRequestHeaders {
    headers['Cache-Control'] = 'no-cache'
    delete headers['X-Act-For-Member-Id']
    delete headers['Pragma']
    delete headers['Expires']
    return headers
}
