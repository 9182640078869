import { render, staticRenderFns } from "./sonstige-unternehmensdaten.vue?vue&type=template&id=13abd025&scoped=true"
import script from "./sonstige-unternehmensdaten.vue?vue&type=script&lang=js"
export * from "./sonstige-unternehmensdaten.vue?vue&type=script&lang=js"
import style0 from "./sonstige-unternehmensdaten.vue?vue&type=style&index=0&id=13abd025&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13abd025",
  null
  
)

export default component.exports